import { ChangeEvent, FC, FocusEvent, useState } from 'react';
import { getInputProps } from '@rjsf/utils';
import InputField from 'destiny/dist/components/molecules/inputField';
import { defaultFn } from 'destiny/dist/constants/index';
import { trackMixpanel } from 'utils/mixpanel';

const InputWidget: FC<any> = (props) => {
  const {
    schema,
    id,
    name,
    options,
    label,
    value,
    type,
    placeholder,
    required,
    disabled,
    readonly,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    rawErrors,
    hideError,
    uiSchema,
    registry,
    formContext,
    ...rest
  } = props;

  const [state, setState] = useState<any>(value);

  const { onChangeOverride = defaultFn, schemaId = '' } = options;

  const handleChange = ({ target: { value: val } }: ChangeEvent<HTMLInputElement>) => {
    const newValue = val === '' ? options?.emptyValue || '' : val;

    setState(newValue);
  };

  const handleBlur = ({ target: { value: val } }: FocusEvent<HTMLInputElement>) => {
    onChangeOverride({ [name]: state });
    onBlur(id, val);
  };

  const handleFocus = ({ target: { value: val } }: FocusEvent<HTMLInputElement>) => onFocus(id, val);

  const inputProps = { ...rest, ...getInputProps(schema, type, options) };
  const hasError = rawErrors?.length > 0 && !hideError;

  return (
    <>
      <InputField
        inputTagProps={{
          id: id,
          label: label,
          value: state,
          placeholder: placeholder,
          disabled: disabled,
          readOnly: readonly,
          autoFocus: autofocus,
          error: hasError,
          errors: hasError ? rawErrors : undefined,
          onChange: handleChange,
          onBlur: handleBlur,
          onFocus: handleFocus,
          eventId: `${schemaId?.toUpperCase()}_${name?.toUpperCase()}_INPUT`,
          eventCallback: trackMixpanel,
          ...inputProps,
        }}
        inputFieldWrapperClass='tw-w-full !tw-my-0'
        size='SMALL'
      />
    </>
  );
};

export default InputWidget;
