import { FC } from 'react';
import { FieldTemplateProps, TitleFieldProps } from '@rjsf/utils';
// import { Text } from 'destiny/dist/components/atoms/text';
import { Label } from 'destiny/dist/components/molecules/label';
import { SupporterInfo } from 'destiny/dist/components/molecules/supporterInfo';
import { SUPPORT_INFO_TYPES } from 'destiny/dist/constants/molecules/supporterInfo';

export const TitleFieldTemplate: FC<TitleFieldProps> = () => {
  // commenting out temporarily to avoid error
  // const { title } = props;
  return null;
  // return <Text textClass='f-20-500 tw-text-TEXT_PRIMARY tw-mb-[34px]'>{title}</Text>;
};

export const FieldTemplate: FC<FieldTemplateProps> = (props) => {
  const { label, hidden, children, displayLabel, rawErrors, schema } = props;

  const hasError = rawErrors && rawErrors?.length > 0;

  const list: any = schema.enum ?? null;

  return (
    !hidden &&
    (!list || (list && list?.length > 1)) && (
      <div className='tw-mb-4'>
        {displayLabel && <Label title={label} titleClass='f-12-300 tw-text-TEXT_SECONDARY tw-mb-3' />}
        {children}
        {hasError && (
          <SupporterInfo
            type={SUPPORT_INFO_TYPES.ERROR}
            text={hasError ? schema.errorMessage : ''}
            wrapperClass='tw-mt-3'
          />
        )}
      </div>
    )
  );
};

export const FieldErrorTemplate = () => null;

export const ErrorListTemplate = () => null;
