import { FC } from 'react';
import { defaultFn } from 'destiny/dist/constants';
import FileUploader from 'components/file-uploader/FileUploader';
import FileUploaderWrapper from 'components/file-uploader/FileUploaderWrapper';

const FileUploaderWidget: FC<any> = (props) => {
  const {
    id,
    schema: { footer, acceptedFormats },
    value,
    onChange,
  } = props;

  const handleSelect = (value: any) => {
    onChange(value);
  };

  return (
    <FileUploaderWrapper
      Component={FileUploader}
      footer={footer}
      filesSelected={value}
      onFilesSelect={handleSelect}
      disableNext={defaultFn}
      acceptedFormats={acceptedFormats}
      id={id}
      className='!tw-mt-0'
    />
  );
};

export default FileUploaderWidget;
