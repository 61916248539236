import { FC } from 'react';
import { CHEVRON_DOWN, CROSS_GRAY } from 'constants/icons';
import { Dropdown } from 'destiny/dist/components/organisms/dropdown';
import { defaultFn } from 'destiny/dist/constants';
import Image from 'next/image';
import { trackMixpanel } from 'utils/mixpanel';

const MultiSelectDropdownWidget: FC<any> = (props) => {
  const {
    schema: { items: { enum: enumList = [] } = {} } = {},
    name = '',
    options: { schemaId = '' } = {},
    onChange = defaultFn,
    value,
    placeholder = 'Select',
  } = props;

  const handleSelect = (selectedValue: any) => {
    onChange(selectedValue.map((item: any) => item.value));
  };

  const list = enumList?.reduce((acc: Array<{ label: string; value: string }>, value: string) => {
    acc.push({
      label: value,
      value: value,
    });

    return acc;
  }, []);

  return (
    <Dropdown
      onChange={handleSelect}
      options={list}
      dropdownIndicator={<Image src={CHEVRON_DOWN} alt='dropdownIndicator' height={16} width={16} priority />}
      closeIcon={<Image src={CROSS_GRAY} alt='closeIcon' height={10} width={10} className='tw-ml-2' priority />}
      isMulti
      controlled
      value={list.filter((item: any) => value?.includes(item.value))}
      eventCallback={trackMixpanel}
      id={`${schemaId?.toUpperCase()}_${name?.toUpperCase()}_MULTI_SELECT_DROPDOWN`}
      placeholder={placeholder}
    />
  );
};

export default MultiSelectDropdownWidget;
