import { FC } from 'react';
import { Text } from 'destiny/dist/components/atoms/text';
import { ICONS } from 'components/icons/constants';
import IconWrapper from 'components/icons/IconWrapper';

interface FormInfoProps {
  data: {
    display_name: string;
  };
  wrapperClassName?: string;
  textClassName?: string;
}

const FormInfo: FC<FormInfoProps> = ({
  data: { display_name = '' },
  textClassName = '',
  wrapperClassName = 'tw-py-4',
}) => {
  return (
    <>
      <div className={`tw-flex tw-w-full tw-items-center ${wrapperClassName}`}>
        <Text textClass={`tw-text-TEXT_PRIMARY f-14-400 tw-mr-2 tw-whitespace-nowrap ${textClassName}`}>
          {display_name}
        </Text>
        <IconWrapper icon={ICONS.DASHED_BORDER} className='tw-w-full' />
      </div>
    </>
  );
};

export default FormInfo;
