import { Fragment } from 'react';
import SkeletonElement from 'components/skeletons/SkeletonElement';

const commonStyle = 'tw-h-[63px] tw-mb-6 tw-rounded-[10px]';

const SkeletonLoader = () => {
  return (
    <>
      <SkeletonElement className={`tw-w-full ${commonStyle}`} />
      {Array(2)
        .fill('')
        .map((_, index) => {
          return (
            <Fragment key={index}>
              <div className='tw-flex tw-justify-between'>
                <SkeletonElement elementCount={2} className={`tw-w-[calc(50%-12px)] ${commonStyle}`} />
              </div>
              <SkeletonElement elementCount={3} className={`tw-w-full ${commonStyle}`} />
            </Fragment>
          );
        })}
    </>
  );
};

export default SkeletonLoader;
