import { FC } from 'react';
import { RadioList } from 'destiny/dist/components/organisms/radioList';
import { defaultFn } from 'destiny/dist/constants/index';
import { MenuItem } from 'types';
import { trackMixpanel } from 'utils/mixpanel';

const RadioListWidget: FC<any> = ({
  schema: { enum: enumList = [] } = {},
  value,
  disabled = false,
  onChange = defaultFn,
}) => {
  const handleSelect = ({ value: selectedValue }: any) => {
    onChange(selectedValue);
  };

  const list = enumList?.reduce(
    (acc: Array<{ label: string; value: string }>, { id, value }: { id: string; value: string }) => {
      acc.push({
        label: value,
        value: id,
      });

      return acc;
    },
    []
  );

  return list?.length > 1 ? (
    <RadioList
      list={list}
      onSelect={handleSelect}
      customSelectedIndex={list?.findIndex((e: MenuItem) => e?.value === value) || 0}
      // TODO: Need to provide this style via Form Schema from BE. Adding now since it's only used in  Add Bene Form.
      buttonWrapperStyle='tw-w-1/2'
      isClearable={false}
      id='RJSF_RADIO_WIDGET'
      eventCallback={trackMixpanel}
      isDisabled={disabled}
    />
  ) : null;
};

export default RadioListWidget;
