import { FC } from 'react';
import { CHEVRON_DOWN, SELECTED_GREEN } from 'constants/icons';
import { Dropdown } from 'destiny/dist/components/organisms/dropdown';
import { defaultFn } from 'destiny/dist/constants/index';
import { OptionsType } from 'destiny/dist/types/dropdown';
import Image from 'next/image';
import { trackMixpanel } from 'utils/mixpanel';

const DropdownWidget: FC<any> = ({
  schema: { enum: enumList = [] } = {},
  name = '',
  options: { schemaId = '' } = {},
  onChange = defaultFn,
  disabled = false,
  value,
}) => {
  const handleSelect = ({ value: selectedValue }: any) => {
    onChange(selectedValue);
  };

  const list = enumList?.reduce(
    (acc: Array<{ label: string; value: string }>, { id, value, ...rest }: { id: string; value: string }) => {
      acc.push({
        label: value,
        value: id,
        ...rest,
      });

      return acc;
    },
    []
  );
  const defaultValueIndex = list?.findIndex((option: OptionsType) => option?.value === value);

  return (
    <Dropdown
      selectFieldWrapperClass='tw-w-full tw-my-0'
      onChange={handleSelect}
      options={list}
      defaultValue={list?.[defaultValueIndex]}
      dropdownIndicator={<Image src={CHEVRON_DOWN} alt='dropdownIndicator' height={16} width={16} priority />}
      selectedIcon={
        <Image src={SELECTED_GREEN} alt='selected' height={16} width={16} className='tw-ml-auto tw-mr-4' priority />
      }
      customStyles={{
        control: {
          padding: '0px 24px',
          minHeight: '44px',
          height: '44px',
        },
        valueContainer: {
          minHeight: 'unset',
        },
        menu: {
          zIndex: '100',
        },
      }}
      id={`${schemaId?.toUpperCase()}_${name?.toUpperCase()}_DROPDOWN`}
      eventCallback={trackMixpanel}
      isDisabled={disabled}
    />
  );
};

export default DropdownWidget;
